const ArrowSvg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.2">
      <path
        d="M24 4C35.02 4 44 12.98 44 24C44 35.02 35.02 44 24 44C12.98 44 4 35.02 4 24C4 12.98 12.98 4 24 4ZM18.42 25.06L25.48 32.12C25.78 32.42 26.16 32.56 26.54 32.56C26.92 32.56 27.3 32.42 27.6 32.12C28.18 31.54 28.18 30.58 27.6 30L21.6 24L27.6 18C28.18 17.42 28.18 16.46 27.6 15.88C27.02 15.3 26.06 15.3 25.48 15.88L18.42 22.94C17.82 23.52 17.82 24.48 18.42 25.06Z"
        fill="white"
      />
    </g>
  </svg>
);
export default ArrowSvg;
